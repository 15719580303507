.ProjectWrapper header {
 height: 50px;
 display: flex;
 flex-direction: row;
 align-items: center;
 justify-content: space-between;
}

.ProjectWrapper header div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.ProjectWrapper header button {
    border: 0;
    background-color: transparent;
    font-size: var(--font-size-500);
    padding: var(--spacing-400);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.ProjectWrapper header p {
    margin: 0;
    margin-left: var(--spacing-300);
    font-size: var(--font-size-400);
}

.ProjectWrapper header a {
    margin-right: var(--spacing-400);
    font-size: var(--font-size-400);
}

.ProjectWrapper .projectContainer {
    height: calc(100vh - 50px);
    position: relative;
}