
.ProjectSelector {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;  
    padding: var(--spacing-700);
    background-color: var(--bg-1);
}

.ProjectSelector h1 {
    font-size: var(--font-size-600);
    margin: 0;
    margin-bottom: var(--spacing-200);
}

.ProjectSelector p {
    font-size: var(--font-size-400);
    margin-top: var(--spacing-200);
    margin-bottom: var(--spacing-500);
}

.ProjectSelector ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow-y: auto;
}

.ProjectSelector li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: var(--spacing-600);
    background-color: var(--bg-2);
    border-radius: 7px;
    margin-bottom: var(--spacing-600);
    box-shadow: var(--box-shadow-200);
}

.ProjectSelector li .text {
    display: flex;
    flex-direction: column;
    width: 50%;
    max-width: 70%;
}

.ProjectSelector li h2 {
    font-size: var(--font-size-500);
    margin: 0;
    margin-bottom: var(--spacing-200);
}

.ProjectSelector li p {
    font-size: var(--font-size-500);
    margin: 0;
}

.ProjectSelector .btnDiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ProjectSelector li button {
    border: 0;
    background-color: var(--primary-bg);
    color: var(--primary-txt);
    border-radius: 7px;
    padding: var(--spacing-300) var(--spacing-600);
    cursor: pointer;
}