.FjesBokBenji {
  width: 100%;
  min-height: 100%;
  background-color: var(--bg-1);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Calibri, sans-serif;
  padding: var(--spacing-600);
}

.FjesBokBenji form {
  background-color: #002159; 
  border-radius: 10px;
  width: 500px;

  display: flex;
  flex-direction: column;
  padding: 30px;
  box-shadow: var(--box-shadow-400);
}

.FjesBokBenji h1,
.FjesBokBenji h2 {
  margin: 5px 0px;
  text-align: center;
  font-weight: 400;
  color: #E6F6FF;
}

.FjesBokBenji h1 {
  font-family: "mistral";
}

.FjesBokBenji .row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.FjesBokBenji label {
  margin: 20px 0px 8px 0px;
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  color: #E6F6FF;
}

.FjesBokBenji .genderLabel {
  margin: 0px;
  font-size: 20px;
}

.FjesBokBenji input[type=radio] {
    font-size: 20px;
    margin-right: 12px;
}

.FjesBokBenji .text {
  border: 2px solid #374f87;
  padding: 10px;

  font-size: 16px;
  color: #002159;

  border-radius: 5px;
  width: 100%;
  margin: auto;
}

.FjesBokBenji .submitBtn {
  cursor: pointer;

  margin: 0px auto;
  margin-top: 30px;
  width: 170px;
  padding: 8px;

  background-color: #27AB83;
  color: #fff;

  border: 0;
  border-radius: 8px;

  font-size: 18px;
}
