.FormServer {
  background-color: #d7d7fd;
  min-height: 100%;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-600);
}

.FormServer button {
    background-color: #000;
    color: #fff;
    border: 0px;
    padding: 6px 10px;
    
    border-radius: 5px;
    margin-right: 4px;
    cursor: pointer;
}

.HomeForm h1 {
    font-size: 30px;
    margin-bottom: 0px;
}

.HomeForm h2 {
    font-size: 20px;
    margin-bottom: 0px;
}

.HomeForm h3 {
    font-size: 16px;
}

.HomeForm img {
    height: 400px;
    width: 400px;

    margin-top: 30px;
    margin-bottom: 20px;
}

.HomeForm .txt {
    margin: 7px 0px;
}

.HomeForm .bold {
    font-weight: bold;
}

.TimesForm {
  padding: var(--spacing-600);
}

.TimesForm table {
  border-collapse: collapse;
  letter-spacing: 1px;
}

.TimesForm caption {
  margin-bottom: var(--spacing-600);
  font-size: var(--font-size-600);
}

.TimesForm td,
.TimesForm th {
  border: 1px solid rgb(190, 190, 190);
  padding: var(--spacing-300) var(--spacing-500);
}

.TimesForm p {
    margin: 0;
}


.SpeakerForm {
    padding: var(--spacing-400);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SpeakerForm h1 {
    font-size: 24px;
}

.SpeakerForm .section {
    background-color: #D8D8BC;
    padding: var(--spacing-300) var(--spacing-500);
    border-radius: 20px;
    margin-bottom: var(--spacing-500);
    max-width: 900px;
}

.SpeakerForm .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.SpeakerForm .column {
    display: flex;
    flex-direction: column;
}

.SpeakerForm img {
    height: 200px;
    width: auto;
    border-radius: 20px;
}

@media screen and (max-width: 850px) {
    .SpeakerForm img {
        height: 100px;
    }
}

.RegistrationForm {
    padding: var(--spacing-300);
}

.RegistrationForm form {
    display: flex;
    flex-direction: column;
    margin-top: var(--spacing-700);
    width: 300px;
}

.RegistrationForm form .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-400);
}

.RegistrationForm input {
    padding: var(--spacing-200);
    border-radius: 13px;
}

.RegistrationForm .btnDiv {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
}

.RegistrationForm .btnDiv input {
    margin: 0;
    margin-left: 7px;
    background-color: #ededed; 
    color: #000;
    padding: 10px;
    border: 1px solid black;
    cursor: pointer;
    border-radius: 8px;
}


.DataReturn {
    padding: var(--spacing-300);
}


