.BenjamTechCV {
    height: 100%;
    width: 100vw;
    background-color: var(--bg-1);
    display: flex;
    justify-content: center;
    align-items: center;  
}

.BenjamTechCV .centerDiv {
    padding: var(--spacing-700);
    background-color: var(--bg-2);
    border-radius: 7px;
    box-shadow: var(--box-shadow-200);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.BenjamTechCV h3 {
    font-size: var(--font-size-600);
    margin: 0;
}

.BenjamTechCV p {
    margin: var(--spacing-400);
    font-size: var(--font-size-500);
    width: 50%;
    margin-bottom: var(--spacing-700);
}

.BenjamTechCV a {
    font-size: var(--font-size-500);
    margin-bottom: var(--spacing-200);
}